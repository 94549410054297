import React from "react";
import {
  SubLayout,
  ClinicsParticle,
  CareerParticle,
  ContactParticle,
  Greetings,
} from "../components";
import Seo from "../components/Seo";
// import Seo from "../components/seo";

const GreetingsPage = () => {
  return (
    <SubLayout>
      <Seo
        title="ご挨拶"
        description="医療法人社団慶育会の理事長並びに各分院の院長からのご挨拶文を見ることができます。"
        keywords="医療法人社団 慶育会,グレースメディカルグループ,在宅医療,訪問診療,訪問医療,ご挨拶,理事長挨拶,GREETINGS"
      />
      <Greetings />
      <ClinicsParticle />
      <CareerParticle />
      <ContactParticle />
    </SubLayout>
  );
};

export default GreetingsPage;
